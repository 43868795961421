import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

import { isAdmin } from '../../utils.js';

const Navigation = () => (
	<AuthUserContext.Consumer>
		{authUser =>
			<header>
				<div className="logo">
					<Link to={ROUTES.HOME}>Home</Link>
				</div>
				
				{ authUser ?
					<ul>
						<li>
							<Link to={ROUTES.ACCOUNT}>Account</Link>
						</li>

						{ !!isAdmin(authUser) && (
							<li>
								<Link to={ROUTES.ADMIN}>Admin</Link>
							</li>
						)}
					</ul>
				:
					null
				}
			</header>
		}
	</AuthUserContext.Consumer>
);

export default Navigation;