import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';

import './css/index.scss';
import reportWebVitals from './reportWebVitals';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<App />
	</FirebaseContext.Provider>,
	document.getElementById('root')
);

reportWebVitals();
