import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import normalize from 'us-states-normalize';

import Marker from '../Marker';
import GoogleMap from '../GoogleMap';
import Spinner from '../Spinner';

import * as LOCATIONS from '../../constants/locations';

const containerStyle = {
	height: 'calc(100vh - 65px)',
	top: '65px',
	left: 0,
	right: 0,
	position: 'absolute'
}

const createMapOptions = (maps) => {
    return {
        zoomControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
            style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT
        },
		fullscreenControl: false,
        mapTypeControl: true,
		clickableIcons: false
    };
}

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
	const bounds = new maps.LatLngBounds();
  
	places.forEach((place) => {
		bounds.extend(new maps.LatLng(
			place.lat,
			place.lng,
	  	));
	});
	return bounds;
};

const bindMarkerClickListener = (map, maps) => {
	const markers = document.getElementsByClassName('marker');

	Array.prototype.forEach.call(markers, item => {
		maps.event.addDomListener(item, 'click', () => {
			const lat = parseFloat(item.getAttribute('data-lat'));
			const lng = parseFloat(item.getAttribute('data-lng'));
			map.panTo({lat: lat, lng: lng});
			map.setZoom(13);

			if (window.innerWidth > 1023) {
				map.panBy(-210, 0);
			} else if (window.innerWidth > 575) {
				map.panBy(-160, 0);
			} else {
				map.panBy(0, 165);
			}
		});
	});
};
  
// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
	maps.event.addDomListenerOnce(map, 'idle', () => {
		maps.event.addDomListener(window, 'resize', () => {
			if (window.innerWidth > 1023) {
				map.fitBounds(bounds, {left:420, top:30, bottom:30});
			} else if (window.innerWidth > 575) {
				map.fitBounds(bounds, {left:320, top:30, bottom:30});
			} else {
				map.fitBounds(bounds, {bottom:330, right:30, left:30});
			}
	  	});
	});
};

const apiIsLoaded = (map, maps, places) => {
	// Get bounds by our places
	const bounds = getMapBounds(map, maps, places);
	// Fit map to bounds
	if (window.innerWidth > 1023) {
		map.fitBounds(bounds, {left:420, top:30, bottom:30});
	} else if (window.innerWidth > 575) {
		map.fitBounds(bounds, {left:320, top:30, bottom:30});
	} else {
		map.fitBounds(bounds, {bottom:330, right:30, left:30});
	}
	// Bind listeners
	bindResizeListener(map, maps, bounds);
	bindMarkerClickListener(map, maps);
};

const getPlaces = async (employees) => {
	let places = [];

	for (let employee of employees) {
		const data = employee;
		const email = data.email;
		const username = email.split('@')[0];
		const firstName = data.firstName;
		const lastName = data.lastName;
		const city = data.city;
		const state = normalize(data.state) ? normalize(data.state, { returnType: 'name' }) : data.state;
		const name = `${firstName} ${lastName}`;
		const { lat, lng } = data.coordinates;

		places.push({
			email: email,
			username: username,
			city: city,
			state: state,
			lat: lat,
			lng: lng,
			name: name,
			text: name
		});
	}

	return places;
}

class MapComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			employees: props.employees,
			places: [],
			loading: true
		};
	}

	componentDidMount() {
		getPlaces(this.state.employees)
			.then(places => {
				this.setState({ 
					places: places,
					loading: false
				});
			});
	}

	render() {
		const { 
			places,
			loading
		 } = this.state;

		return (
			<>
				{loading 
					? <Spinner />
					: !isEmpty(places) && (
						<GoogleMap
							defaultCenter={LOCATIONS.MINNEAPOLIS}
							defaultZoom={4}
							options={createMapOptions}
							style={containerStyle}
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
						>
							{places.map((place, index) => (
								<Marker
									key={index}
									email={place.email}
									username={place.username}
									name={place.name}
									city={place.city}
									state={place.state}
									lat={place.lat}
									lng={place.lng}
									onClick={(event) => this.props.handleMarkerClick(event)}
									onMouseOver={(event) => this.props.handleMarkerMouseOver(event)}
									onMouseOut={(event) => this.props.handleMarkerMouseOut(event)}
								/>
							))}
						</GoogleMap>
				)}
			</>
		)
	}
}


const Map = (props) => (
	<section className="map">
		<MapComponent
			{...props}
			employee={props.employee}
			handleMarkerClick={props.handleMarkerClick}
			handleMarkerMouseOver={props.handleMarkerMouseOver}
			handleMarkerMouseOut={props.handleMarkerMouseOut}
		/>
	</section>
);

export default Map;