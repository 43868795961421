import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
	constructor() {
		app.initializeApp(config);
		app.analytics();

		this.auth = app.auth();
		this.db = app.database();
		this.storage = app.storage();
		this.functions = app.functions();
	}

	// Employees API
	employee = id => this.db.ref(`employees/${id}`);

	employees = () => this.db.ref('employees').orderByChild('timestamp');

	doRemoveEmployee = id => this.db.ref(`employees/${id}`).remove();

	// User API
	user = uid => this.db.ref(`users/${uid}`);

	users = () => this.db.ref('users');

	doUpdateAdmin = (uid, value) => {
		this.db.ref(`users/${uid}`)
			.update({ admin: value })
			.then(console.log('admin updated'))
			.catch(error => {
				console.log(error);
				return error;
			});
		
		const updateAdminRole = this.functions.httpsCallable('updateAdminRole');
		
		updateAdminRole({
			id: uid,
			admin: value
		}).then(result => {
			console.log(result);
		}).catch(error => {
			console.log(error);
			return error;
		});
	};

	// Auth API
	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password);

	// Merge Auth and DB User API
	onAuthUserListener = (next, fallback) =>
		this.auth.onAuthStateChanged(authUser => {
			if (authUser) {
				this.user(authUser.uid)
					.once('value')
					.then(snapshot => {
						const dbUser = snapshot.val();

						// default empty roles
						if (!dbUser.admin) {
							dbUser.admin = 0;
						}

						// merge auth and db user
						authUser = {
							uid: authUser.uid,
							email: authUser.email,
							...dbUser
						};

						next(authUser);
					});
			} else {
				fallback();
			}
		});

	// Storage API
	doImageUpload = (file, id, callback) => {
		this.storage.ref().child(`images/${id}`)
			.put(file, { cacheControl: 'public,max-age=86400' })
			.then(() => {
				return `https://firebasestorage.googleapis.com/v0/b/media-employees.appspot.com/o/images%2F${id}_360x600?alt=media` 
			})
			.then(url => callback(url))
			.catch(error => { 
				return error;
			});
	}

	doImageDelete = (id) => {
		this.storage.ref().child(`images/${id}_360x600`)
			.delete()
			.then(console.log('File deleted.'))
			.catch(error => {
				return error;
			});
	}

	doUpdateImage = (id, value, callback) => {
		this.db.ref(`employees/${id}`)
			.update({ imgUrl: value })
			.then(callback)
			.catch(error => {
				return error;
			});
	}
}

export default Firebase;