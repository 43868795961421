import React from 'react';
import styled from 'styled-components';
import InfoWindow from '../InfoWindow';

import star from '../../images/star-solid.svg';

const Wrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	background-image: url(${star});
	background-repeat: no-repeat;
	filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
	user-select: none;
	transform: translate(-50%, -50%);
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	&:hover {
		z-index: 1;
	}
`;

const Marker = ({ email, username, name, lat, lng, city, state, onClick, onMouseOver, onMouseOut }) => (
	<>
		<Wrapper
			className='marker'
			id={`${username}-marker`}
			data-username={username}
			data-email={email}
			data-name={name}
			data-lat={lat}
			data-lng={lng}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		/>
		<InfoWindow
			email={email}
			username={username}
			name={name}
			city={city}
			state={state}
		/>
	</>
);

export default Marker;