import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Geocode from 'react-geocode';

import Spinner from '../Spinner';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

Geocode.setApiKey(process.env.REACT_APP_GEOCODING_API_KEY);

const CreatePage = () => (
	<section className="admin">
		<div className="wrapper">
			<p style={{ marginTop: 0, marginBottom: '2rem' }}>A strength of the culture of Augsburg Fortress Publishers is a sense of strong community. To strengthen our community we want to get to know more about you! Please answer the questions below and upload a fun photo with a brief description. Once you have submitted your form, it will be electronically posted on the intranet for everyone to see. Please contact Alaina Bobbitt with any questions. We look forward to getting to know you better!</p>
			<CreateForm />
		</div>
	</section>
);

const INITIAL_STATE = {
	id: '',
	books: '',
	city: '',
	timestamp: '',
	email: '',
	firstJob: '',
	firstName: '',
	imgDescription: '',
	imgUrl: '',
	lastName: '',
	like: '',
	newSkill: '',
	pauseRewind: '',
	position: '',
	state: '',
	imgFile: null,
	error: null,
	loading: false
};

const getCoordinates = async (city, state) => {
	const response = await Geocode.fromAddress(`${city}, ${state}`);
	let { lat, lng } = response.results[0].geometry.location;

	// adjust lat and lng randomly to avoid stacking markers
	const getPlusMin = () => Math.random() < 0.5 ? -1 : 1;
	lat = lat + (Math.random()*.01*getPlusMin());
	lng = lng + (Math.random()*.01*getPlusMin());

	return { lat, lng };
}

class CreateFormBase extends Component {
	constructor(props) {
		super(props);

		if(props.employeeId) {
			this.state = {
				edit: props.edit,
				id: props.employeeId,
				books: props.books,
				city: props.city,
				timestamp: props.timestamp,
				email: props.email,
				firstJob: props.firstJob,
				firstName: props.firstName,
				imgDescription: props.imgDescription,
				imgUrl: props.imgUrl,
				lastName: props.lastName,
				like: props.like,
				newSkill: props.newSkill,
				pauseRewind: props.pauseRewind,
				position: props.position,
				state: props.state,
				coordinates: props.coordinates
			};
		} else {
			this.state = { ...INITIAL_STATE };
		}
	}

	onSubmit = async event => {
		event.preventDefault();
		this.setState({ loading: true });

		const setEmployeeData = (url = '') => {
			if (url) {
				imgUrl = url;
			}
			
			this.props.firebase
				.employee(id)
					.set({
						books,
						city,
						timestamp,
						email,
						firstJob,
						firstName,
						imgDescription,
						imgUrl,
						lastName,
						like,
						newSkill,
						pauseRewind,
						position,
						state,
						coordinates
					})
				.then(() => {
					this.setState({ ...INITIAL_STATE });

					if (this.state.edit) {
						this.props.history.push(ROUTES.ADMIN);
					} else {
						this.props.history.push(ROUTES.HOME);
					}
				})
				.catch(error => {
					this.setState({
						error,
						loading: false
					});
				});
		};

		let {
			id,
			books,
			city,
			timestamp,
			email,
			firstJob,
			firstName,
			imgDescription,
			imgUrl,
			lastName,
			like,
			newSkill,
			pauseRewind,
			position,
			state,
			imgFile,
			coordinates
		} = this.state;

		if (!id) {
			id = email.split('@')[0];
			id = id.split('.').join('');
		}

		if (!timestamp) {
			timestamp = parseInt(new Date().getTime() / 1000);
		}

		if (!coordinates) {
			coordinates = await getCoordinates(city, state);
		}

		if (imgFile) {
			this.props.firebase
				.doImageUpload(imgFile, id, function(url) {
					this.setState({
						imgUrl: url
					}, setEmployeeData(url));
				}.bind(this));
		} else {
			setEmployeeData();
		}
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	deleteHandler = (event, id) => {
		event.preventDefault();
		let isConfirmed = window.confirm("Are you sure you want to delete this employee?");

		if (isConfirmed) {
			this.props.firebase
				.doRemoveEmployee(id)
				.then(() => {
					this.setState({ ...INITIAL_STATE });
					this.props.history.push(ROUTES.ADMIN);
				})
				.catch(error => {
					this.setState({ error });
				});
		}
	}

	deleteImage = (event, id, callback) => {
		event.preventDefault();
		let isConfirmed = window.confirm("Are you sure you want to delete this image?");

		if (isConfirmed) {
			if (!id) return;

			try {
				this.props.firebase
					.doUpdateImage(id, '', callback);
				this.props.firebase
					.doImageDelete(id);
			} catch (error) {
				console.log(error);
				this.setState({ error });
			}
		}
	}

	imageCallback = (value) => {
		this.setState({
			imgUrl: value
		})
	}

	onFileChange = (event) => {
		this.setState({ imgFile: event.target.files[0] });
	}

	render() {
		const {
			edit,
			id,
			books,
			city,
			email,
			firstJob,
			firstName,
			imgDescription,
			imgUrl,
			lastName,
			like,
			newSkill,
			pauseRewind,
			position,
			state,
			error,
			loading
		} = this.state;

		return (
			<>
				{loading && <Spinner />}
				
				<form name="create-form" className="create-form" onSubmit={this.onSubmit}>
					<div className="flex-form">
						<div>
							<label htmlFor="email">Email <span className="asterisk">*</span></label>
							<input
								name="email"
								value={email}
								onChange={this.onChange}
								type="email"
								disabled={edit}
								required
							/>
						</div>

						<div className="half">
							<label htmlFor="firstName">First Name <span className="asterisk">*</span></label>
							<input
								name="firstName"
								value={firstName}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div className="half">
							<label htmlFor="lastName">Last Name <span className="asterisk">*</span></label>
							<input
								name="lastName"
								value={lastName}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div className="half">
							<label htmlFor="city">City <span className="asterisk">*</span></label>
							<input
								name="city"
								value={city}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div className="half">
							<label htmlFor="state">State <span className="asterisk">*</span></label>
							<input
								name="state"
								value={state}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="position">Position, Team <span className="asterisk">*</span></label>
							<input
								name="position"
								value={position}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="like">What do you like about Augsburg Fortress Publishers? <span className="asterisk">*</span></label>
							<textarea
								name="like"
								value={like}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="firstJob">What was your first job ever? <span className="asterisk">*</span></label>
							<textarea
								name="firstJob"
								value={firstJob}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="books">What books are on your nightstand? <span className="asterisk">*</span></label>
							<textarea
								name="books"
								value={books}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="newSkill">If you could gain a new skill in 10 minutes, what would it be? <span className="asterisk">*</span></label>
							<textarea
								name="newSkill"
								value={newSkill}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="pauseRewind">Would you rather have a pause or rewind button in your life and why? <span className="asterisk">*</span></label>
							<textarea
								name="pauseRewind"
								value={pauseRewind}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>

						<div>
							<label htmlFor="imgFile">Share a photo of yourself doing an activity you enjoy, you with family, with friends, or anything that tells us a little more about you. <span className="asterisk">*</span></label>

							{ imgUrl ? 
								<div>
									<img src={imgUrl} 
										style={{ display: "block", margin: "0.5rem auto" }} 
										alt={ id ? `${firstName} ${lastName}` : "employee" }
									/>
									<div className="delete-image">
										<button className="danger" onClick={e => this.deleteImage(e, id, this.imageCallback(''))}>Delete Image</button>
									</div>
								</div>
							:
								<input 
									name="imgFile"
									type="file"
									onChange={this.onFileChange}
									accept="image/*"
									required
								/>
							}
						</div>

						<div>
							<label htmlFor="imgDescription">Please describe the picture you shared. <span className="asterisk">*</span></label>
							<textarea
								name="imgDescription"
								value={imgDescription}
								onChange={this.onChange}
								type="text"
								required
							/>
						</div>
					</div>
					
					{edit ?
						<span className="flex-buttons">
							<span>
								<button type="submit">Update</button>

								<button type="button" onClick={() => this.props.history.push(ROUTES.ADMIN)}>
									Cancel
								</button>
							</span>

							<span>
								<button type="button" className="danger" onClick={e => this.deleteHandler(e, id)}>
									Delete employee
								</button>
							</span>
						</span>
						: <span className="flex-buttons create">
							<button type="submit">Submit</button>
							<button onClick={() => this.props.history.push(ROUTES.HOME)}>
								Cancel
							</button>
						</span>
					}

					{ error && <p className="error-message">{error.message}</p> }
				</form>
			</>
		);
	}
}

const CreateForm = compose(
	withRouter,
	withFirebase
)(CreateFormBase);
 
export default CreatePage;

export { CreateForm };