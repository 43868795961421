import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import Card from '../Card';
import Map from '../Map';

import { showCard, mouseOverInfo, mouseOutInfo } from '../../utils.js';

class HomeBase extends Component {
	constructor(props) {
		super(props);
		this.setEmployeeState = this.setEmployeeState.bind(this);
		this.state = {
			employeeId: '',
			employee: {},
			employees: [],
			loading: true
		};
	}

	setEmployeeState = (employeeId) => {
		const employee = this.state.employees.find(object => object.id === employeeId);
		this.setState({ 
			employeeId: employeeId,
			employee: employee
		});
	}

	handleMarkerClick = (event) => {
		const clickedMarker = event.target;

		if (!clickedMarker.classList.contains('active')) {
			const employeeId = clickedMarker.getAttribute('data-username');
			this.setEmployeeState(employeeId);
			showCard(employeeId);
		}
	}

	handleMarkerMouseOver = (event) => {
		const employee = event.target.getAttribute('data-username');
		mouseOverInfo(employee);
	}

	handleMarkerMouseOut = (event) => {
		const employee = event.target.getAttribute('data-username');
		mouseOutInfo(employee);
	}

	componentDidMount() {
		this.props.firebase.employees().once('value').then(snapshot => {
			let employeesList = [];
			snapshot.forEach(child => {
				employeesList.unshift({
					...child.val(),
					id: child.key
				});
			});
			
			return employeesList;
		}).then(employeesList => {
			this.setState({
				employees: employeesList,
				loading: false
			});
		});
	}

	componentWillUnmount() {
		this.props.firebase.employees().off();
	}

	render() {
		return (
			<div style={{ height: '100vh', backgroundColor: '#8ab4f7' }}>
				{ !this.state.loading ? 
					<>
						<Card 
							setEmployeeState={this.setEmployeeState}
							employeeId={this.state.employeeId}
							employee={this.state.employee}
							employees={this.state.employees}
						/>
						<Map
							handleMarkerClick={this.handleMarkerClick}
							handleMarkerMouseOver={this.handleMarkerMouseOver}
							handleMarkerMouseOut={this.handleMarkerMouseOut}
							employees={this.state.employees}
						/>
					</>
					: null
				}
			</div>
		);
	}
}

const HomePage = withFirebase(HomeBase);

export default HomePage;