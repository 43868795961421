import React, { Component, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import Spinner from '../Spinner';
import Users from '../Users';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import NotFoundPage from '../404';

import { isAdmin, isSuper } from '../../utils.js';
 
class AdminPageBase extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			employees: []
		};
	}

	deleteHandler = (event, id, firstName, lastName) => {
		event.preventDefault();
		let isConfirmed = window.confirm(`Are you sure you want to delete ${firstName} ${lastName}?`);

		if (isConfirmed) {
			this.props.firebase.doRemoveEmployee(id);
		}
	};

	componentDidMount() {
		this.props.firebase.employees().on('value', snapshot => {
			let employeesList = [];
			snapshot.forEach(child => {
				employeesList.unshift({
					...child.val(),
					id: child.key
				});
			});
			
			this.setState({
				employees: employeesList,
				loading: false
			});
		});
	}

	componentWillUnmount() {
		this.props.firebase.employees().off();
	}

	render() {
		const { employees, loading } = this.state;

		if (!employees) {
			return (
				<section>
					<div className="wrapper">
						<h1>Admin</h1>
						<AdminButtons />
					</div>
				</section>
			);
		}

		return (
			<AuthUserContext.Consumer>
				{authUser => (
					<>
						{
							this.props.match.isExact ?
								<section className="admin">
									<div className="wrapper">
										<h1>Admin</h1>
										
										{loading && <Spinner />}

										<AdminButtons {...authUser} />

										<Employees employees={employees} deleteHandler={this.deleteHandler} />

										<AdminButtons {...authUser} />
									</div>
								</section>
								: null
						}

						<Switch>
							<Route exact path='/admin' />
							<Route exact path='/admin/users' component={Users} />
							<Route path='/admin/*' component={NotFoundPage} />
						</Switch>
					</>
				)}
			</AuthUserContext.Consumer>
		);
	}
}

const Employees = ({ employees, deleteHandler }) => {
	const [filter, setFilter] = useState('');

	return (
		<>
			<div className="filter">
				<input id="filterField"
					name="filterField"
					type="text"
					placeholder="Filter employees..."
					value={filter}
					onChange={e => setFilter(e.target.value)}
					className={filter ? "active" : ""}
				/>

				{ filter && 
					<button id="clearFilter"
						onClick={() => setFilter('')}
					/>
				}
			</div>

			<ul className="admin-list">
				{employees
					.filter(employee => 
						(employee.firstName + ' ' + employee.lastName).toLowerCase()
							.includes(filter.toLowerCase()) 
							|| filter === '')
					.map(employee => (
						<li key={employee.id}>
							<div className="row">
								<div className="data">
									<span><strong>{employee.firstName} {employee.lastName}</strong></span>
								</div>
								
								<div className="buttons">
									<Link to={`/edit/${employee.id}`}>
										<button type="button">
											Edit
										</button>
									</Link>

									<Link to="#">
										<button type="button" className="danger" onClick={e => deleteHandler(e, employee.id, employee.firstName, employee.lastName)}>
											Delete
										</button>
									</Link>
								</div>
							</div>
						</li>
					))
				}
			</ul>
		</>
	);
};

const AdminButtons = (authUser) => {
	return (
		<span className="flex-buttons">
			<span>
				<Link to={ROUTES.CREATE}>
					<button type="button">
						Create Employee	
					</button>
				</Link>
				{ !!isSuper(authUser) && (
					<Link to={ROUTES.USERS}>
						<button type="button">
							View Users
						</button>
					</Link>
				)}
			</span>
		</span>
	);
}

const AdminPage = withFirebase(AdminPageBase);

const condition = authUser => authUser && !!isAdmin(authUser);
 
export default withAuthorization(condition)(AdminPage);