export function showCard(username) {
	clearEmployee();
	const employeeInfo = document.getElementById(username+'-info');
	const employeeMarker = document.getElementById(username+'-marker');

	employeeInfo.classList.add('active');
	employeeMarker.classList.add('active');
}

export function mouseOverInfo(username) {
	const employeeInfo = document.getElementById(username+'-info');
	employeeInfo.style.display = 'inline-block';
}

export function mouseOutInfo(username) {
	const employeeInfo = document.getElementById(username+'-info');

	if (!employeeInfo.classList.contains('active')) {
		employeeInfo.style.display = 'none';
	}
}

export function clearEmployee() {
	const activeMarker = document.querySelector('.marker.active');
	const activeWindow = document.querySelector('.info-window.active');

	if (activeMarker) {
		activeMarker.classList.remove('active');
	}

	if (activeWindow) {
		activeWindow.classList.remove('active');
		activeWindow.style.display = 'none';
	}
}

export function isAdmin(authUser) {
	if (authUser) {
		return authUser.admin >= 1;
	} else {
		return false;
	}
}

export function isSuper(authUser) {
	if (authUser) {
		return authUser.admin === 2;
	} else {
		return false;
	}
}