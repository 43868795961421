import React from 'react';

const NotFoundPage = () => {
	return (
		<main className="maintenance">
			<section>
				<div className="wrapper">
					<h1>I have gone astray like a lost sheep...</h1>
					<h2 style={{ marginBottom: "1rem" }}>Ps. 119:176</h2>

					<p>
						Sorry, but the page you&rsquo;re looking for <span className="no-wrap">can&rsquo;t be found.</span><br/>
						Return to our <a href="/">home page</a>.
					</p>
				</div>
			</section>
		</main>
	);
};

export default NotFoundPage;