import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
	<section className="admin">
		<div className="wrapper">
			<h1>Sign Up</h1>
			<SignUpForm />
		</div>
	</section>
);

const INITIAL_STATE = {
	username: '',
	email: '',
	password: '',
	passwordConfirm: '',
	Admin: 0,
	error: null
};

class SignUpFormBase extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
	}

	onSubmit = event => {
		const { email, password, admin } = this.state;

		this.props.firebase
			.doCreateUserWithEmailAndPassword(email, password)
			.then(authUser => {
				// Create a user in firebase realtime db
				return this.props.firebase
					.user(authUser.user.uid)
					.set({
						email,
						admin
					});
			})
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				this.props.history.push(ROUTES.HOME);
			})
			.catch(error => {
				this.setState({ error });
			});

		event.preventDefault();
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const {
			email,
			password,
			passwordConfirm,
			error,
			admin
		} = this.state;

		const isInvalid =
			password !== passwordConfirm ||
			password === '' ||
			email === ''

		return (
			<form onSubmit={this.onSubmit}>
				<input
					name="email"
					value={email}
					onChange={this.onChange}
					type="text"
					placeholder="Email Address"
				/>
				<input
					name="password"
					value={password}
					onChange={this.onChange}
					type="password"
					placeholder="Password"
				/>
				<input
					name="passwordConfirm"
					value={passwordConfirm}
					onChange={this.onChange}
					type="password"
					placeholder="Confirm Password"
				/>
				<input
					name="admin"
					value={admin}
					onChange={this.onChange}
					type="hidden"
				/>
				<button disabled={isInvalid} type="submit">Sign Up</button>

				{error && <p className="error-message">{error.message}</p>}
			</form>
		);
	}
}

const SignUpLink = () => (
	<p>
		Don&rsquo;t have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
	</p>
);

const SignUpForm = compose(
	withRouter,
	withFirebase
)(SignUpFormBase);
 
export default SignUpPage;

export { SignUpForm, SignUpLink };