import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Spinner from '../Spinner';
import { withAuthorization } from '../Session';
import { CreateForm } from '../Create';
import * as ROUTES from '../../constants/routes';

import { isAdmin } from '../../utils.js';

class EditPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			employee: {},
			employeeId: this.props.match.params.employeeId
		};
	}

	componentDidMount() {
		this.setState({ 
			loading: true
		});

		this.props.firebase.employee(this.state.employeeId).once('value', snapshot => {
			const employeeObject = snapshot.val();

			if (employeeObject) {
				this.setState({
					edit: true,
					loading: false,
					employee: employeeObject,
					books: employeeObject.books,
					city: employeeObject.city,
					timestamp: employeeObject.timestamp,
					email: employeeObject.email,
					firstJob: employeeObject.firstJob,
					firstName: employeeObject.firstName,
					imgDescription: employeeObject.imgDescription,
					imgUrl: employeeObject.imgUrl,
					lastName: employeeObject.lastName,
					like: employeeObject.like,
					newSkill: employeeObject.newSkill,
					pauseRewind: employeeObject.pauseRewind,
					position: employeeObject.position,
					state: employeeObject.state
				})
			} else {
				this.setState({
					redirect: true
				})
			}
		});
	}

	componentWillUnmount() {
		this.props.firebase.employee().off();
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={ROUTES.NOT_FOUND} />
		}

		const {
			edit,
			loading,
			employeeId,
			books,
			city,
			timestamp,
			email,
			firstJob,
			firstName,
			imgDescription,
			imgUrl,
			lastName,
			like,
			newSkill,
			pauseRewind,
			position,
			state
		} = this.state;

		if (loading) {
			return (
				<section>
					<div className="wrapper">
						<h1>Edit</h1>
						<Spinner />
					</div>
				</section>
			);
		}

		return (
			<section className="admin">
				<div className="wrapper">
					<h1>Edit&emsp;<span className="employee-id">{employeeId}</span></h1>

					<CreateForm 
						edit={edit}
						employeeId={employeeId}
						books={books}
						city={city}
						timestamp={timestamp}
						email={email}
						firstJob={firstJob}
						firstName={firstName}
						imgDescription={imgDescription}
						imgUrl={imgUrl}
						lastName={lastName}
						like={like}
						newSkill={newSkill}
						pauseRewind={pauseRewind}
						position={position}
						state={state}
					/>
				</div>
			</section>
		);
	}
}

const condition = authUser => authUser && !!isAdmin(authUser);
 
export default withAuthorization(condition)(EditPage);