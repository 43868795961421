import React from 'react';
import { 
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';

import Navigation from '../Navigation';
import HomePage from '../Home';
import Maintenance from '../Maintenance';
import NotFoundPage from '../404';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import PasswordForgetPage from '../PasswordForget';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import CreatePage from '../Create';
import EditPage from '../Edit';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => {
	let maintenance = false;

	if (maintenance) {
		return (
			<Maintenance />
		);
	} else {
		return (
			<Router>
				<main>
					<Navigation />
				
					<Switch>
						<Route exact path={ROUTES.HOME} component={HomePage} />
						<Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
						<Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
						<Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
						<Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
						<Route path={ROUTES.ADMIN} component={AdminPage} />
						<Route exact path={ROUTES.CREATE} component={CreatePage} />
						<Route exact path={ROUTES.EDIT} component={EditPage} />
						<Route exact path={ROUTES.NOT_FOUND} component={NotFoundPage} />
						<Route component={NotFoundPage} />
					</Switch>
				</main>
			</Router>
		);
	}
};

export default withAuthentication(App);