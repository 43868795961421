import React from 'react';

const Spinner = () => (
	<div className='spinner'>
		<div className='loader'>
			<div className='bounce1' />
			<div className='bounce2' />
			<div className='bounce3' />
		</div>
	</div>
);

export default Spinner;