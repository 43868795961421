import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import SignOutButton from '../SignOut';
 
const AccountPage = () => (
	<AuthUserContext.Consumer>
		{authUser => (
			<section className="account admin">
				<div className="wrapper">
					<h1>Account</h1>
					<h2>{authUser.email}</h2>
					<h3>
						{(() => {
							switch (authUser.admin) {
								case 0:
									return 'Basic'
								case 1:
									return 'Admin'
								case 2:
									return 'Super'
								default:
									return null
							}
						})()}
					</h3>
					<PasswordChangeForm />
					<SignOutButton />
				</div>
			</section>
		)}
	</AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;
 
export default withAuthorization(condition)(AccountPage);