import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Spinner from '../Spinner';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';

import { isSuper } from '../../utils.js';
 
class UsersPageBase extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			users: []
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		
		this.props.firebase.users().on('value', snapshot => {
			const usersObject = {};

			snapshot.forEach((childSnapshot) => {
				usersObject[childSnapshot.key] = childSnapshot.val();
			});

			const usersList = usersObject ?
				Object.keys(usersObject).map(key => ({
					...usersObject[key],
					id: key
				}))
				: null;

			this.setState({
				users: usersList,
				loading: false
			});
		});
	}

	componentWillUnmount() {
		this.props.firebase.users().off();
	}

	adminOnChange = event => {
		this.props.firebase.doUpdateAdmin(event.target.name, parseInt(event.target.value));
	};

	render() {
		const { users, loading } = this.state;

		return (
			<AuthUserContext.Consumer>
				{authUser => (
					<section className="admin">
						<div className="wrapper">
							<h1>Users</h1>
							
							{loading && <Spinner />}

							<Users users={users} changeHandler={this.adminOnChange} />

							<span className="flex-buttons">
								<span>
									<Link to={ROUTES.ADMIN}>
										<button type="button">
											Back to Admin
										</button>
									</Link>
								</span>
							</span>
						</div>
					</section>
				)}
			</AuthUserContext.Consumer>
		);
	}
}

const Users = ({ users, changeHandler }) => {
	return (
		<ul className="admin-list">
			{users.map(user => (
				<li key={user.id}>
					<div className="row">
						<div className="data">
							<span><strong>{user.email}</strong></span>
							<span className="admin-status">
								{(() => {
									switch (user.admin) {
										case 0:
											return <span className="basicUser">Basic</span>
										case 1:
											return <span className="adminUser">Admin</span>
										case 2:
											return <span className="superUser">Super</span>
										default:
											return null
									}
								})()}
							</span>
						</div>

						<div className="buttons">
							<form>
								<div>
									<span>Super</span>
									<input type="radio" className="superUser" name={user.id} value={2} checked={user.admin === 2} onChange={changeHandler} />
								</div>

								<div>
									<span>Admin</span>
									<input type="radio" className="adminUser" name={user.id} value={1} checked={user.admin === 1} onChange={changeHandler} />
								</div>
								
								<div>
									<span>Basic</span>
									<input type="radio" className="basicUser" name={user.id} value={0} checked={user.admin === 0} onChange={changeHandler} />
								</div>
							</form>
						</div>
					</div>
				</li>
			))}
		</ul>
	);
};

const UsersPage = withFirebase(UsersPageBase);

const condition = authUser => authUser && !!isSuper(authUser);
 
export default withAuthorization(condition)(UsersPage);