import React from 'react';

const infoWindowStyle = {
	display: 'none',
    position: 'relative',
    left: '9px',
	top: '9px',
	borderRadius: '2px',
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 5,
    fontSize: 12,
    zIndex: 100,
};

const InfoWindow = (props) => {
	return (
		<div className="info-window" style={infoWindowStyle} id={`${props.username}-info`} data-username={props.username} data-email={props.email}>
			<h4 style={{ margin: '0', whiteSpace: 'nowrap' }}>{props.name}</h4>
			<h5 style={{ margin: '0', whiteSpace: 'nowrap', textTransform: 'capitalize' }}>{props.city}, {props.state}</h5>
		</div>
	);
};

export default InfoWindow;